import { render, staticRenderFns } from "./TransactionsView.vue?vue&type=template&id=3b8f795e&scoped=true"
import script from "./TransactionsView.vue?vue&type=script&lang=ts"
export * from "./TransactionsView.vue?vue&type=script&lang=ts"
import style0 from "./TransactionsView.vue?vue&type=style&index=0&id=3b8f795e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b8f795e",
  null
  
)

export default component.exports
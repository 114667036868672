import api from "@/services/api";

import { TransactionType } from "@/types/transaction/Transaction";
class GetTransactions {
  execute = async (
    franchiseId: string,
    startDate?: String | undefined,
    endDate?: String | undefined
  ) => {
    const transactions = await api().get("/transactions", {
      params: {
        franchiseId: franchiseId,
        startDate: startDate,
        endDate: endDate,
      },
    });
    return transactions.data as TransactionType[];
  };
}

export { GetTransactions };

import Vue from "vue";
import VueRouter, { NavigationGuard } from "vue-router";
import HomeView from "../views/home/HomeView.vue";
import PartnersView from "@/views/partners/PartnersView.vue";
import PartnerFormView from "@/views/partners/PartnerFormView.vue";
import TransactionView from "@/views/transactions/TransactionsView.vue";
import Login from "../views/login/Login.vue";
import ForgotPassword from '../views/forgotPassword/ForgotPassword.vue'
Vue.use(VueRouter);

const requireAuth: NavigationGuard = (to, from, next) => {
  const userData = JSON.parse(String(localStorage.getItem("tokens")));
  let token = false;
  if (userData) {
    token = userData.accessToken;
  }
  if (token && to.path === "/login" || to.path === "/forgot-password") {
    next("/");
  } else if (token) {
    next();
  } else {
    next("/login");
  }
};

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/partners",
    name: "partners",
    component: PartnersView,
    beforeEnter: requireAuth,
  },
  {
    path: "/partner/form",
    name: "partnerForm",
    component: PartnerFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/partner/form/:id",
    name: "partnerDetail",
    component: PartnerFormView,
    beforeEnter: requireAuth,
  },
  {
    path: "/transactions",
    name: "transactions",
    component: TransactionView,
    beforeEnter: requireAuth,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: requireAuth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

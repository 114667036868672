import api from "@/services/api";

import { PartnerType } from "@/types/partner/Partner";

class GetAllPartners {
  execute = async (franchiseId: string) => {
    const partners = await api().get(`/partners?franchise=${franchiseId}`);
    return partners.data.partners as PartnerType[];
  };
}

export { GetAllPartners };

import api from "@/services/api";
import { PartnerDetailsType } from "@/types/partner/Partner";

class GetPartner {
  execute = async (id: string) => {
    const partner = await api().get(`/partner/${id}`);
    return partner.data as PartnerDetailsType;
  };
}

export { GetPartner };

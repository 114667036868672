import api from "@/services/api";

class SendOtp {
  execute = async (email: string) => {
    const otp = await api().post(`/send-otp`, {
      email: email,
    });
    return otp.status;
  };
}

export { SendOtp };
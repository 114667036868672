<template>
  <div>
    <div class="header">
      <v-col>
        <v-select
          clearable
          :items="franchises"
          v-model="franchiseId"
          :item-text="displayTextFranchise"
          item-value="id"
          label="Franquia"
          @change="handleLoadPartners"
        ></v-select>
      </v-col>
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo nome, CNPJ, CPF, email, telefone ou cidade"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="partners"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'parceiros por página' }"
        :search="search"
        no-data-text="Nenhum parceiro encontrado"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ item.name }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDocument(item.document) }}</td>
              <td class="d-block d-sm-table-cell">
                {{ phoneNumberFormat(item.phone) }}
              </td>
              <td class="d-block d-sm-table-cell">{{ item.email }}</td>
              <td class="d-block d-sm-table-cell">
                {{ item.address.cityName }} - {{ item.address.state }}
              </td>

              <td class="d-block d-sm-table-cell">{{ formatDate(item.createdAt) }}</td>

              <td class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailPartner(item.id)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { PartnerType } from "@/types/partner/Partner";
import { FranchiseType } from "@/types/franchise/Franchise";
import { GetAllPartners } from "@/services/partners/GetAllPartners";
import { GetAllFranchises } from "@/services/franchises/GetAllFranchises";

import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  data() {
    return {
      mobileBreakpoint: 600,
      loading: false,
      showModal: false,
      showModalRemove: false,
      franchiseId: "",
      franchises: [] as FranchiseType[],
      partners: [] as PartnerType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        {
          text: "Nome",
          value: "name",
        },
        { text: "Documento", value: "document" },
        { text: "Telefone", value: "phone" },
        { text: "Email", value: "email" },
        { text: "Cidade", value: "address.cityName" },
        { text: "Data de cadastro", value: "createdAt" },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async handleLoadPartners() {
      this.loading = true;
      if (this.franchiseId) {
        const getAllPartners = new GetAllPartners();
        await getAllPartners
          .execute(this.franchiseId)
          .then((res) => {
            this.partners = res;
          })
          .catch(() => (this.partners = []));
      } else {
        this.partners = [];
      }
      this.loading = false;
    },
    async handleLoadFranchises() {
      this.loading = true;
      const getAllFranchises = new GetAllFranchises();
      const franchises = await getAllFranchises.execute();
      this.franchises = franchises;
      this.loading = false;
    },
    handleNewPartner() {
      this.$router.push({
        name: "partnerForm",
        params: { modeForm: "new" },
      });
    },
    handleDetailPartner(id: string) {
      this.$router.push(`/partner/form/${id}`);
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
    displayTextFranchise(item: { cityName: string; state: string }): string {
      return `${item.cityName} - ${item.state}`;
    },
  },
  async mounted() {
    await this.handleLoadFranchises();
    if (this.franchises.length > 0) {
      this.franchiseId = this.franchises[0].id;
      this.handleLoadPartners();
    }
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
@/services/partners/GetAllPartners

<template>
  <div>
    <div class="header">
      <v-col cols="12" md="4">
        <v-dialog ref="dialog" v-model="dialog" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="handleFormatDate"
              label="Período de vendas"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="selectedRange" range locale="pt-br">
            <v-spacer></v-spacer>
            <v-btn @click="closeDialog" color="primary">Cancelar</v-btn>
            <v-btn @click="dialog = !dialog" color="primary">Ok</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          :items="franchises"
          v-model="franchiseId"
          :item-text="displayTextFranchise"
          item-value="id"
          label="Franquia"
        ></v-select>
      </v-col>
      <v-col cols="12" md="2" align-self="center">
        <v-btn block @click="handleGetTransactions" elevation="0" color="#e5183d" dark
          >Buscar</v-btn
        >
      </v-col>
    </div>
    <v-skeleton-loader
      v-if="!!loading"
      tile
      loading
      class="mx-auto"
      type="card"
      elevation="{5}"
      boilerplate
    />
    <v-card v-else>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquise pelo documento do parceiro, nome do parceiro, valor da venda ou comissão da franquia"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :header-props="{ sortByText: 'Filtrar por' }"
        :headers="headers"
        :items="transactions"
        :items-per-page="10"
        :footer-props="{ 'items-per-page-text': 'transações por página' }"
        :search="search"
        no-data-text="Nenhuma transação encontrada"
        class="elevation-0"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td class="d-block d-sm-table-cell">{{ formatDate(item.createdAt) }}</td>
              <td class="d-block d-sm-table-cell">{{ item.partnerName }}</td>
              <td class="d-block d-sm-table-cell">{{ formatDocument(item.partnerDocument) }}</td>
              <td class="d-block d-sm-table-cell">R$ {{ formatPrice(item.value) }}</td>
              <td class="d-block d-sm-table-cell">R$ {{ formatPrice(item.franchiseValue) }}</td>
              <td class="d-block d-sm-table-cell"><status-badge-vue :status="item.status" /></td>
              <td class="d-block d-sm-table-cell">
                <v-icon size="24" color="#e5183d" @click="handleDetailPartner(item.id)"
                  >mdi-menu</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import StatusBadgeVue from "@/components/statusBadge/StatusBadge.vue";

import { TransactionType } from "@/types/transaction/Transaction";
import { FranchiseType } from "@/types/franchise/Franchise";
import { GetTransactions } from "@/services/transactions/GetTransactions";
import { GetAllFranchises } from "@/services/franchises/GetAllFranchises";

import { format } from "date-fns";

interface SortItem {
  name: string;
  phone: string;
  createdAt: string;
}

export default defineComponent({
  components: { StatusBadgeVue },
  data() {
    const today = new Date();
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);
    const formattedToday = today.toISOString().split("T")[0];
    const formattedThirtyDaysAgo = thirtyDaysAgo.toISOString().split("T")[0];
    return {
      dialog: false,
      selectedRange: [formattedThirtyDaysAgo, formattedToday],
      startDate: formattedThirtyDaysAgo,
      endDate: formattedToday,
      mobileBreakpoint: 600,
      loading: false,
      showModal: false,
      showModalRemove: false,
      franchiseId: "",
      franchises: [] as FranchiseType[],
      transactions: [] as TransactionType[],
      search: "",
      sortBy: [] as ReadonlyArray<SortItem>,
      sortDesc: false,
      noDataText: "Nenhum dado disponível",
      headers: [
        { text: "Data da venda", value: "createdAt" },
        {
          text: "Parceiro",
          value: "partnerName",
        },
        { text: "Documento do parceiro", value: "partnerDocument" },
        { text: "Valor da venda", value: "value" },
        { text: "Comissão da franquia", value: "franchiseValue" },
        { text: "Status", value: "status" },
        {
          text: "Ações",
          value: "actions",
        },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    async handleGetTransactions() {
      this.loading = true;
      if (this.franchiseId) {
        const getTransactions = new GetTransactions();
        if (this.selectedRange.length > 0) {
          if (this.selectedRange[0] > this.selectedRange[1]) {
            this.startDate = this.selectedRange[1];
            this.endDate = this.selectedRange[0];
          } else {
            this.startDate = this.selectedRange[0];
            this.endDate = this.selectedRange[1];
          }
        }
        const transactions = await getTransactions.execute(
          this.franchiseId,
          this.startDate,
          this.endDate
        );
        this.transactions = transactions;
        this.transactions = this.transactions.filter(
          (transaction: TransactionType) => transaction.status == "CONFIRMED"
        );
      } else {
        this.transactions = [];
      }
      this.loading = false;
    },
    async handleLoadFranchises() {
      this.loading = true;
      const getAllFranchises = new GetAllFranchises();
      const franchises = await getAllFranchises.execute();
      this.franchises = franchises;
      this.loading = false;
    },
    handleNewPartner() {
      this.$router.push({
        name: "partnerForm",
        params: { modeForm: "new" },
      });
    },
    handleDetailPartner(id: string) {
      this.$router.push(`/partner/form/${id}`);
    },
    phoneNumberFormat: (value: string): string => {
      if (!value) return "";
      const phone = "(" + value.substr(0, 2) + ") " + value.substr(2, 5) + "-" + value.substr(7, 8);
      return phone;
    },
    formatDate: (date: string): string => {
      const dateFormated = format(new Date(date), "dd/MM/yyyy HH:mm:ss");
      return dateFormated;
    },
    formatDocument: (document: string): string => {
      if (document.length === 11) {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      if (document.length === 14) {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      }
      return document;
    },
    formatPrice: (value: number): string => {
      if (!value) return "";
      return value.toFixed(2).replace(".", ",");
    },
    displayTextFranchise(item: { cityName: string; state: string }): string {
      return `${item.cityName} - ${item.state}`;
    },
  },
  computed: {
    handleFormatDate(): string[] {
      const datesString = this.selectedRange?.map((dates: string | undefined) => {
        if (dates) {
          const [year, month, day] = dates.split("-");
          return ` ${day}/${month}/${year}`;
        }
        return "";
      });
      return datesString || [];
    },
  },
  async mounted() {
    await this.handleLoadFranchises();
    if (this.franchises.length > 0) {
      this.franchiseId = this.franchises[0].id;
      this.handleGetTransactions();
    }
  },
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>

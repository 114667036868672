<template>
  <v-app>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card>
            <v-list-item>
              <v-list-item-content class="headline">
                <v-list-item-avatar>
                  <v-img contain :src="logo" alt="LOGO BRP"></v-img>
                </v-list-item-avatar>
                <p class="subtitle-1">Alteração de senha</p>
              </v-list-item-content>
            </v-list-item>

            <v-card-text>
              <v-form
                v-if="currentStep === 'sendOtp'"
                ref="formSendOtp"
                v-model="valid"
                @submit.prevent="handleSendOtp"
              >
                <p class="subtitle-2">Insira o seu email para recuperar a sua senha</p>
                <v-text-field
                  v-model="email"
                  label="Email"
                  outlined
                  :rules="ruleEmail"
                ></v-text-field>
                <v-btn
                  type="submit"
                  :disabled="!valid"
                  :dark="valid"
                  color="#ee2c4f"
                  class="mt-5"
                  block
                >
                  Enviar email
                </v-btn>
              </v-form>
              <v-form
                v-if="currentStep === 'validateOtp'"
                ref="formValidateOtp"
                v-model="valid"
                @submit.prevent="handleValidOtp"
              >
                <p class="subtitle-2">Insira o código que recebeu no seu email</p>
                <v-text-field
                  v-model="code"
                  label="Código"
                  outlined
                  :rules="ruleCode"
                ></v-text-field>
                <v-btn
                  type="submit"
                  :disabled="!valid"
                  :dark="valid"
                  color="#ee2c4f"
                  class="mt-5"
                  block
                >
                  Validar código
                </v-btn>
              </v-form>
              <v-form
                v-if="currentStep === 'changePassword'"
                ref="formChangePassword"
                v-model="valid"
                @submit.prevent="handleChangePassword"
              >
                <p class="subtitle-2">Insira a sua nova senha</p>
                <v-text-field
                  v-model="password"
                  label="Nova senha"
                  outlined
                  append-icon="mdi-eye"
                  :rules="rulePassword"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-btn
                  type="submit"
                  :disabled="!valid"
                  :dark="valid"
                  color="#ee2c4f"
                  class="mt-5"
                  block
                >
                  Alterar senha
                </v-btn>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { SendOtp } from "@/services/user/SendOtp";
import { ValidateOtp } from "@/services/user/ValidateOtp";
import { ResetPassword } from "@/services/user/ResetPassword";
export default defineComponent({
  data() {
    return {
      valid: false,
      currentStep: "sendOtp",
      showPassword: false,
      email: "",
      code: "",
      document: "",
      password: "",
      confirmPassword: "",
      ruleEmail: [
        (value: string) => {
          if (value) return true;
          return "O campo é obrigatório";
        },
      ],
      ruleCode: [
        (value: string) => {
          if (value) return true;
          return "O campo é obrigatório";
        },
      ],
      rulePassword: [
        (value: string) => {
          if (value) return true;
          return "O campo é obrigatório";
        },
      ],
      logo: require("../../assets/logo-login.png"),
    };
  },
  methods: {
    async handleSendOtp() {
      const sendOtp = new SendOtp();
      await sendOtp
        .execute(this.email)
        .then((res: any) => {
          if (res === 201) {
            this.currentStep = "validateOtp";
          } else {
            this.$store.dispatch("showSnack", {
              message: "Erro ao validar a conta",
              color: "red",
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao encontrar a conta",
            color: "red",
          });
        });
    },
    async handleValidOtp() {
      const validateOtp = new ValidateOtp();
      await validateOtp
        .execute(this.email, this.code)
        .then((res: any) => {
          if (res.data.isValid) {
            this.document = res.data.document;
            this.currentStep = "changePassword";
          } else {
            this.$store.dispatch("showSnack", {
              message: "Erro ao validar o código",
              color: "red",
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao validar o código",
            color: "red",
          });
        });
    },
    async handleChangePassword() {
      const resetPassword = new ResetPassword();
      await resetPassword
        .execute(this.document, this.code, this.password)
        .then((res: any) => {
          if (res.data.success) {
            this.$store.dispatch("showSnack", {
              message: "Senha alterada com sucesso",
              color: "green",
            });
            this.$router.push("/login");
          } else {
            this.$store.dispatch("showSnack", {
              message: "Erro ao alterar a senha",
              color: "red",
            });
          }
        })
        .catch(() => {
          this.$store.dispatch("showSnack", {
            message: "Erro ao alterar a senha",
            color: "red",
          });
        });
    },
  },
});
</script>

<style scoped>
.v-application {
  background: #e5183d;
  height: 100vh;
}
.v-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.v-card-title {
  background-color: #3f51b5;
  color: white;
}
.v-card-text {
  padding: 24px;
}
.text-center {
  text-align: center;
}
</style>

import api from "@/services/api";

class ResetPassword {
  execute = async (document: string, otp: string, password: string) => {
    const validate = await api().put(`/reset-password`, {
      otp: otp,
      document: document,
      password: password,
    });
    return validate;
  };
}

export { ResetPassword };